<template>
          <div>
            <!-- 页面标题 -->
            <h3>
                实验室活动
                <hr />
            </h3>

          <p v-for="(item, index) of news" :key="index" class="news-content">
            👏
            <!-- {{ item.eng }}
            <br /> -->
            <a :href="item.newUrl" style="text-decoration: none; color: #000" target="blank">
              <b>
                {{ item.ch }}
              </b>
            </a>
          </p>
          <br />

    <!-- 走马灯效果 -->
    <h3 class="activity">
      活动照片
      <hr />
    </h3>

    <!-- 走马灯效果开始 -->
    <el-carousel
      :interval="3000"
      type="card"
      height="60vh"
      class="scenery-el-carousel"
    >
      <el-carousel-item
        class="scenery-carousel-item"
        v-for="(item, index) in scenerys"
        :key="index"
        style="margin: 0px"
      >
        <div class="sceneryPhoto-box">
          <el-image
            :src="item.url"
            :preview-src-list="Array(item.url)"
            class="image"
          ></el-image>
          <!-- <img :src="item.url" /> -->
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 屏幕小于450时显示基础走马灯效果 -->
    <el-carousel :interval="3000" class="small-scenery-el-carousel">
      <el-carousel-item
        class="scenery-carousel-item"
        v-for="(item, index) in scenerys"
        :key="index"
        style="margin: 0px"
      >
        <div class="sceneryPhoto-box">
          <el-image
            :src="item.url"
            :preview-src-list="Array(item.url)"
            class="image"
          ></el-image>
<!--           <img :src="item.url" /> -->
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 小屏走马灯效果 -->
  </div>
</template>

<script>
export default {
  name: "Gallery",
  data() {
    return {
      news: [
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年07月，研究室召开2024上半学年总结会!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年07月，研究室成员赴国防科技大学进行项目交流！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年06月，研究室联合欢送第一届全体毕业生!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年05月，研究室成员赴浙江大学进行项目交流!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年04月，研究室成员组织轰趴派对!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年03月，研究室成员赴四川大学进行项目交流!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年01月，研究室召开2023下半学年总结会!',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2024年01月，研究室成员赴国防科技大学进行交流！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年12月，研究室组织师生乒乓球赛！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年12月，研究室成员赴北京进行项目答辩并顺利通过结题验收！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年12月，研究室成员参加嵩山论坛，并赴解放军信息工程大学进行交流！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年11月，研究室承办的中国通信学会青托沙龙成功召开！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年11月，郭将军和杜将军一行专家对研究室承担的JKW项目进行实地考察和指导！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年11月，研究室承办的IEEE CCCI会议成功召开！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年07月，研究室召开2023上半学年总结会！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年07月，研究室成员组织歌神争霸赛（KTV版）！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年06月，研究室成员参加四川大学主办的网络安全论坛！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年04月，研究室成员赴成都阿木实验室进行交流培训！',
        },
        {
          //newUrl: './static/56.Blockchain_Intelligence_for_Internet_of_Vehicles_Challenges_and_Solutions.pdf',
          ch: '2023年02月，研究室成员赴杭州华信设计院进行项目交流！',
        },

      ],
      currentDate: new Date(),

      students: [
        {
          url: "",
          srcList:
            "",
          message: "Highly Cited Researchers 2022",
          time: "",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/Highly%20Cited%20Researchers%2020211.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/Highly%20Cited%20Researchers%202021.png",
          message: "Highly Cited Researchers 2021",
          time: "2021年",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8741.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%874.png",
          message: "Best Paper Award",
          time: "2021年11月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%87%8D%E5%BA%86%E8%8B%B1%E6%89%8D%E8%AE%A1%E5%88%921.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%87%8D%E5%BA%86%E8%8B%B1%E6%89%8D%E8%AE%A1%E5%88%92.png",
          message: "重庆英才计划",
          time: "2020年11月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E7%88%B1%E6%80%9D%E7%BB%B4%E5%B0%942020%E4%B8%AD%E5%9B%BD%E9%AB%98%E8%A2%AB%E5%BC%95%E5%AD%A6%E8%80%851.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E7%88%B1%E6%80%9D%E7%BB%B4%E5%B0%942020%E4%B8%AD%E5%9B%BD%E9%AB%98%E8%A2%AB%E5%BC%95%E5%AD%A6%E8%80%85.png",
          message: "爱思维尔2020中国高被引学者",
          time: "2020年11月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/TVT%E6%9C%80%E4%BD%B3%E8%AE%BA%E6%96%871.jpg",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/TVT%E6%9C%80%E4%BD%B3%E8%AE%BA%E6%96%87.jpg",
          message: "2020 Best Land Transportation Paper Award",
          time: "2020年10月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%A6%99%E6%B1%9F%E5%AD%A6%E8%80%851.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%A6%99%E6%B1%9F%E5%AD%A6%E8%80%85.png",
          message: "香江学者",
          time: "2019年03月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ISJ%E6%9C%80%E4%BD%B3%E8%AE%BA%E6%96%871.jpg",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ISJ%E6%9C%80%E4%BD%B3%E8%AE%BA%E6%96%87.jpg",
          message: "2019 IEEE Systems Journal Bast Paper Award",
          time: "2019年",
        },
        /* {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8751.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%875.png",
          message: "CITS 2018 Best Paper Award",
          time: "2018年6月",
        }, */
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8761.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%876.png",
          message: "DataCom-2017 BEST PAPER AWARD",
          time: "2017年",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E4%BC%98%E5%8D%9A20151.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E4%BC%98%E5%8D%9A2015.png",
          message: "2015 ACM DALIAN DOCTORAL DISSERTATION AWARD",
          time: "2015年5月",
        },

        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/AI-20001.png",
          srcList: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/AI-2000.png",
          message: "A2000 Most Influential Scholar Award Honorable Mention",
          time: "2022年1月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E7%8E%8B%E5%B0%8F%E6%B4%81%E9%9D%92%E5%B9%B4%E4%BA%BA%E6%89%8D1.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E7%8E%8B%E5%B0%8F%E6%B4%81%E9%9D%92%E5%B9%B4%E4%BA%BA%E6%89%8D.png",
          message: "青年人才托举工程",
          time: "2020年2月",
        },

        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E4%BC%98%E5%8D%9A20191.jpg",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E4%BC%98%E5%8D%9A2019.jpg",
          message: "2019 ACM DALIAN DOCTORAL DISSERTATION AWARD",
          time: "2019年",
        },

        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8731.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%873.png",
          message: "辽宁省自然科学奖学术成果奖一等奖",
          time: "2019年11月",
        },

        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%9D%92%E5%B9%B4%E4%BA%BA%E6%89%8D%E6%89%98%E4%B8%BE%E5%B7%A5%E7%A8%8B1.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E9%9D%92%E5%B9%B4%E4%BA%BA%E6%89%8D%E6%89%98%E4%B8%BE%E5%B7%A5%E7%A8%8B.png",
          message: "青年人才托举工程",
          time: "2018年02月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E6%96%B0%E6%98%9F1.jpg",
          srcList: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/ACM%E6%96%B0%E6%98%9F.jpg",
          message: "2017 ACM Dalian RISING STAR AWARD",
          time: "2017年",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8721.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%872.png",
          message: "辽宁省自然科学奖学术成果奖一等奖",
          time: "2017年9月",
        },
        {
          url: "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%8711.png",
          srcList:
            "http://img.lins-cqupt.cn/%E8%8E%B7%E5%A5%96/%E5%9B%BE%E7%89%871.png",
          message: "辽宁省自然科学奖学术成果奖壹等",
          time: "2013年8月",
        },
      ],
      scenerys: [
        
        //21届毕业照
        {
          url: "http://link.lins-cqupt.cn/activity/graduation3.jpg",
        },
        //21届毕业照
        {
          url: "http://link.lins-cqupt.cn/activity/graduation1.jpg",
        },
        //ccci茶歇
        {
          url: "http://link.lins-cqupt.cn/ccci%E8%8C%B6%E6%AD%87.jpg",
        },
        
        //ccci邱铁演讲
        {
          url: "http://link.lins-cqupt.cn/CCCI%E4%BC%9A%E8%AE%AE%E9%82%B1%E9%93%81.png",
        },

        //ccci ob教授演讲
        {
          url: "http://link.lins-cqupt.cn/ccci_ob.jpg",
        },
        /*2024.4.20轰趴*/ 
        {
          url: "http://link.lins-cqupt.cn/activity/4.20%E8%BD%B0%E8%B6%B41.jpg",
        },
        // {
        //   url: "http://link.lins-cqupt.cn/activity/4.20%E8%BD%B0%E8%B6%B43.jpg",
        // },
        {
          url: "http://link.lins-cqupt.cn/activity/4.20%E8%BD%B0%E8%B6%B42.jpg",
        },
        // {
        //   url: "http://link.lins-cqupt.cn/activity/4.20%E8%BD%B0%E8%B6%B44.jpg",
        // },

        //2024年1月18号年终总结合影
        {
          url: "http://link.lins-cqupt.cn/activity/20240118%E5%B9%B4%E7%BB%88%E6%80%BB%E7%BB%93%E5%90%88%E5%BD%B12.jpg",
        },

        // 2023年8月份实验室聚餐
        {
            url: "http://link.lins-cqupt.cn/activity/%E8%81%9A%E9%A4%902.jpg",
        },

        // 2023-12-29吃烤全羊
        {
            url: "http://link.lins-cqupt.cn/activity/20231229%E7%83%A4%E5%85%A8%E7%BE%8A.jpg", 
        },
        // ccci聚餐
        {
            url: "http://link.lins-cqupt.cn/activity/ccci%E8%81%9A%E9%A4%90.jpg",
        },
        // ob+ning+gxb
        {
            url: "http://link.lins-cqupt.cn/activity/ob_ning_gxb%20-%20Copy.jpg",
        },
        
        // CCCi合照
        {
            url: "http://link.lins-cqupt.cn/activity/ccci%E5%90%88%E7%85%A7.jpg",
        },
        
        {
          url: "http://link.lins-cqupt.cn/872B9D043064C0CB9E9508544FFC8839.jpg",
        },
        {
          url: "http://link.lins-cqupt.cn/QQ图片20230328112108.jpg",
        },
        {
          url: "http://link.lins-cqupt.cn/activity/8376971E39586A5CEEC8B9B6C779EFF4.jpg",
        },
        {
          url: "http://link.lins-cqupt.cn/QQ图片20220703131913.jpg", //研三和老师合照
        },
        
        // {
        //   url: "http://link.lins-cqupt.cn/activity/无人机培训1.JPG",
        // },
        // {
        //   url: "http://link.lins-cqupt.cn/activity/无人机培训2.JPG",
        // },
        // CCCI会议颁奖（NING)
        // {
        //     url: "http://link.lins-cqupt.cn/activity/ccci%E4%BC%9A%E8%AE%AE%E9%A2%81%E5%A5%96%E5%AE%81%E5%AF%BC.JPG",
        // },      
        // {
        //   url: "http://link.lins-cqupt.cn/校内景色2.jpg",
        // },
        // {
        //   url: "http://link.lins-cqupt.cn/校内景色6.jpg",
        // }, 
        // {
        //   url: "http://link.lins-cqupt.cn/5B27C29B54F4710B83039363C783AB3A.jpg",
        // },
        
        // {
        //   url: "http://link.lins-cqupt.cn/C36D1D74902896C032BA6A9EEB27EDF5.jpg",
        // },
        // {
        //   url: "http://link.lins-cqupt.cn/EDF44356ABB31CFE863711D9EFA3486E.jpg",
        // },

        
      ],
      
    };
  },
};
</script>

<style scoped>
.el-row {
  margin: 40px 0 100px 0;
}
.el-carousel {
  margin-top: 15px;
}
.el-row h3 {
  margin: 20px;
}
.activity {
  margin-bottom: 50px;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}
.box {
  margin-bottom: 30px;
}

.image {
  width: 100%;
  /* height: 20vw; */
  display: block;
  /* text-align: center; */
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.vertical {
  border-left: 3px solid rgb(0, 183, 255);
  margin-left: -1px;
  padding-left: 5px;
  font-family: "Lucida Bright", "Open Sans";
  font-style: oblique;
}

/* 走马灯效果 */
/******* 风景走马灯效果 *********/
.scenery-el-carousel {
  display: block;
  overflow: hidden;
}
.small-scenery-el-carousel {
  display: none;
}
.sceneryPhoto-box {
  width: 100%;
}
.sceneryPhoto-box img {
  width: 100%;
  height: 25vw;
}

@media (max-width: 1200px) {
  .icon-box {
    width: 100%;
    margin: 15px 0 0 0;
    height: auto;
  }

  /* 显示大的走马灯 */
  .scenery-el-carousel {
    display: block;
    width: 100%;
    height: 25vh;
    overflow: hidden;
  }
  /* 每个图片的box */
  .sceneryPhoto-box {
    width: 100%;
    height: 25vh;
  }
  /* box里面的图片 */
  .sceneryPhoto-box .el-image {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 450px) {
  /* 隐藏大走马灯 */
  .scenery-el-carousel {
    display: none;
  }
  /* 显示小走马灯 */
  .small-scenery-el-carousel {
    display: block;
    width: 100%;
    height: 25vh;
    overflow: hidden;
  }
  .news-content {
  line-height: 22px;
}
}
</style>
